import React from 'react';
// import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';

import useRenderLocation from '../hooks/useRenderLocation';

import Portal from './Portal';
import Overlay from './Overlay';

const Modal = ({ children, ...props }) => {
  const history = useHistory();
  const location = useRenderLocation();

  const onClose = () => {
    history.push({ ...location, state: { preventScroll: true } });
  };

  return (
    <Portal>
      <Overlay onClick={onClose}>
        <div className="modal" {...props}>
          {children}
        </div>
      </Overlay>
    </Portal>
  );
};

export default Modal;
