import { css } from '@emotion/react';

const wideGridStyle = css`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;

  @media (max-width: 1800px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1024px) {
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const narrowGridStyle = css`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 2rem;

  @media (max-width: 1800px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 1400px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const wideGridWithHeaderStyle = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;

  @media (max-width: 1800px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 1024px) {
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const cutAtMaxStyle = css`
  .cinematic:nth-of-type(10) ~ .cinematic {
    display: none;
  }

  @media (max-width: 1800px) {
    .cinematic:nth-of-type(8) ~ .cinematic {
      display: none;
    }
  }

  @media (max-width: 1400px) {
    .cinematic:nth-of-type(6) ~ .cinematic {
      display: none;
    }
  }

  @media (max-width: 768px) {
    .cinematic:nth-of-type(4) ~ .cinematic {
      display: none;
    }
  }
`;

const cutAtMaxHeaderStyle = css`
  .cinematic:nth-of-type(8) ~ .cinematic {
    display: none;
  }

  @media (max-width: 1800px) {
    .cinematic:nth-of-type(6) ~ .cinematic {
      display: none;
    }
  }

  @media (max-width: 1400px) {
    .cinematic:nth-of-type(4) ~ .cinematic {
      display: none;
    }
  }

  @media (min-width: 480px) and (max-width: 768px) {
    .cinematic:nth-of-type(2) ~ .cinematic {
      display: none;
    }
  }

  @media (max-width: 480px) {
    .cinematic:nth-of-type(4) ~ .cinematic {
      display: none;
    }
  }
`;

export {
  narrowGridStyle,
  wideGridStyle,
  wideGridWithHeaderStyle,
  cutAtMaxStyle,
  cutAtMaxHeaderStyle,
};
