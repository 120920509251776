import React from 'react';
import { css } from '@emotion/react';

import CinematicList from './CinematicList';
import NavButton from './NavButton';

const style = css`
  display: grid;
  grid-template-columns: 1fr 7fr;
  grid-gap: 2rem;
  margin-bottom: 4rem;

  .header {
    position: relative;
    background: #f8f8f8;
    border-radius: 0.3rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    color: #FFF;
    background: #282828;

    &:before {
      z-index: 1;
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.4;
      background-position: center center;
      background-size: cover;
    }

    .content {
      z-index: 2;
      position: relative;
      margin-bottom: 2rem;

      .title {
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
      }

      .body {
        opacity: 0.7;
      }
    }

    .cta {
      z-index: 2;
      margin-top: auto;
    }
  }

  grid-template-columns: 1fr 4fr;

  @media (max-width: 1800px) {
    grid-template-columns: 1fr 3fr;
  }

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 2fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export default ({
  cinematics,
  children,
  title,
  to,
  ...props
}) => (
  <div css={style} {...props}>
    <div className="header">
      <div className="content">
        <div className="title">{title}</div>
        <div className="body">
          {children}
        </div>
      </div>
      <div className="cta">
        <NavButton to={to} appearance="primary" stretch>View all</NavButton>
      </div>
    </div>
    <CinematicList cinematics={cinematics} withHeader cut />
  </div>
);
