import React, {
  createContext,
  useContext,
  useState,
} from 'react';
import parseJSON from 'date-fns/parseJSON';
import formatISO from 'date-fns/formatISO';

const AppContext = createContext();

const useAppContext = () => useContext(AppContext);

const AppProvider = ({ children }) => {
  const [cinematics, setCinematics] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [lastSeen, setLastSeen] = useState(localStorage.getItem('lastSeen'));

  console.log(lastSeen);

  const [tags, setTags] = useState(null);
  const [tagTypes, setTagTypes] = useState(null);

  const findByIdOrSlug = (needle, haystack) => {
    if (haystack === null) {
      return null;
    }

    if (typeof needle === 'number') {
      return haystack.byId.get(needle);
    }

    return haystack.bySlug.get(needle);
  };

  const getTag = (id) => findByIdOrSlug(id, tags);
  const getType = (id) => findByIdOrSlug(id, tagTypes);

  const getGame = (cinematic) => {
    const type = getType('games');
    const game = cinematic.allTags.find((id) => {
      const tag = getTag(id);
      return tag.type === type.id;
    });
    return getTag(game);
  };

  const [autoplay, setAutoplay] = useState(true);

  const toggleAutoplay = () => setAutoplay(!autoplay);

  const setData = (data) => {
    setTags({
      byId: new Map(data.tags.map((s) => [s.id, s])),
      bySlug: new Map(data.tags.map((s) => [s.slug, s])),
    });
    setTagTypes({
      byId: new Map(data.tagTypes.map((s) => [s.id, s])),
      bySlug: new Map(data.tagTypes.map((s) => [s.slug, s])),
    });

    setLastUpdate(data.lastUpdate);

    setCinematics(data.cinematics.map(({
      publishedAt,
      releasedAt,
      ...rest
    }) => ({
      publishedAt: parseJSON(publishedAt),
      releasedAt: parseJSON(releasedAt),
      ...rest,
    })));
  };

  return <AppContext.Provider
    value={{
      tags: tags === null ? null : tags.byId,
      getType,
      getTag,
      getGame,
      setData,
      cinematics,
      autoplay,
      setAutoplay,
      toggleAutoplay,
      lastUpdate,
      lastSeen,
      setLastSeen: (date) => {
        localStorage.setItem('lastSeen', formatISO(date));
        setLastSeen(formatISO(date));
      },
    }}
  >
    {children}
  </AppContext.Provider>;
};

export {
  AppContext,
  AppProvider,
  useAppContext,
};
