import React from 'react';
import { css } from '@emotion/react';

const style = css`
  display: none;
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translate(-50%, 100%);
  width: 100vw;
  max-width: 8rem;
  background: #282828;
  color: #ddd;
  font-weight: normal;
  font-size: 0.85rem;
  padding: 0.8rem;
  border-radius: 0.2rem;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent #282828 transparent;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }
`;

export default ({ children, ...props }) => (
  <div className="tooltip" css={style} {...props} >{children}</div>
);
