import React from 'react';
import { css } from '@emotion/react';

import useTheme from '../hooks/useTheme';
import useCinematics from '../hooks/useCinematics';
import useFilters from '../hooks/useFilters';
import {
  wideGridStyle,
  wideGridWithHeaderStyle,
  cutAtMaxStyle,
  cutAtMaxHeaderStyle,
} from '../utils/styles';

import CinematicLink from './CinematicLink';
import FilterBar from './FilterBar';
import VideoPoster from './VideoPoster';

const style = (withHeader) => css`
  margin-bottom: ${withHeader ? 0 : '4rem'};
`;

const listStyle = (withHeader, cut) => css`
  ${cut && (withHeader ? cutAtMaxHeaderStyle : cutAtMaxStyle)}
`;

export default ({
  cinematics: allCinematics,
  withHeader = false,
  filters = [],
  filterOpts = false,
  cut = false,
}) => {
  const { fontWeight } = useTheme();
  const { getGame } = useCinematics();
  const {
    cinematics,
    filterConfigs,
    orderConfig,
    values,
  } = useFilters({
    cinematics: allCinematics,
    filters,
    filterOpts,
  });

  return (
    <div className="cinematic-list" css={style(withHeader, cut)}>
      <FilterBar
        filters={filters}
        filterConfigs={filterConfigs}
        orderConfig={orderConfig}
        values={values}
      />
      <div css={[withHeader ? wideGridWithHeaderStyle : wideGridStyle, listStyle(withHeader, cut)]}>
        {cinematics.map((cinematic) => {
          const { id, name, poster } = cinematic;
          const game = getGame(cinematic);
          return (
            <div
              key={id}
              className="cinematic"
              css={css`
                min-width: 0;

                a {
                  text-decoration: none;
                  color: inherit;

                  img {
                    transition: transform 300ms;
                  }

                  &:hover {
                    img {
                      transform: scale(1.05);
                    }
                  }
                }
              `}
            >
              <CinematicLink id={id} cinematics={cinematics}>
                <VideoPoster imageUrl={poster} />
                <div
                  css={css`
                    margin-top: 0.5rem;
                    font-weight: ${fontWeight('normal', 'medium')};
                    line-height: 130%;
                  `}
                >{game ? game.name : '-'}</div>
                <div
                  css={css`
                    font-size: 0.9rem;
                    opacity: 0.5;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  `}
                >
                  {name}
                </div>
              </CinematicLink>
            </div>
          );
        })}
      </div>
    </div>
  );
};
