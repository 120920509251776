import { useLocation, useRouteMatch } from 'react-router-dom';

const useRenderLocation = () => {
  const currentLocation = useLocation();
  const isModal = [
    useRouteMatch('/cinematics/:id/') !== null,
    useRouteMatch('/submit/') !== null,
    useRouteMatch('/about/') !== null,
  ].some((val) => val);

  // eslint-disable-next-line no-nested-ternary
  return currentLocation.state && currentLocation.state.location
    ? currentLocation.state.location
    : (isModal ? { pathname: '/' } : currentLocation);
};

export default useRenderLocation;
