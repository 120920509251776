import React from 'react';
import { Link } from 'react-router-dom';

import useRenderLocation from '../hooks/useRenderLocation';

const ModalLink = ({ to, ...props }) => {
  const location = useRenderLocation();

  return (
    <Link
      to={{
        pathname: to,
        state: { location },
      }}
      {...props}
    />
  );
};

export default ModalLink;
