import React from 'react';
import { css } from '@emotion/react';

import { Link } from 'react-router-dom';

import useTheme from '../hooks/useTheme';

import Icon from './Icon';
import ModalLink from './ModalLink';

const style = (appearance, fontWeight, fontFamily, stretch) => css`
  text-decoration: none;
  color: inherit;
  display: ${stretch ? 'flex' : 'inline-flex'};
  align-items: center;
  padding: 0 0.8rem;
  height: 2.4rem;
  border-radius: 0.3rem;
  cursor: pointer;
  position: relative;
  font-size: 0.95rem;
  font-family: ${fontFamily('normal')};
  border: 0;
  background: transparent;

  & > .icon-left {
    margin-right: 0.3rem;
  }

  ${appearance === 'primary' && css`
    background: #30b77a;
    color: #FFF;
    font-weight: ${fontWeight('normal', 'medium')};
    justify-content: center;

    &:hover {
      background: #42c78b;
      text-decoration: none;
    }
  `}

  ${appearance === 'secondary' && css`
    background: #5c6aeb;
    color: #FFF;
    font-weight: ${fontWeight('normal', 'medium')};
    justify-content: center;

    &:hover {
      background: #6976ec;
      text-decoration: none;
    }
  `}

   ${appearance === 'normal' && css`
    transition: background-color 150ms ease-in-out;

    &:hover {
      background: #f8f8f8;
      text-decoration: none;
    }
  `}

`;

const NavButton = ({
  to = undefined,
  onClick = undefined,
  toModal = undefined,
  className = '',
  appearance = 'normal',
  icon: iconName,
  stretch = false,
  children,
  ...props
}) => {
  const { fontWeight, fontFamily } = useTheme();
  const icon = typeof iconName !== 'undefined'
    ? <div className="icon-left"><Icon icon={iconName} /></div>
    : null;

  const commonProps = {
    css: style(appearance, fontWeight, fontFamily, stretch),
    className: `button ${className}`,
    onClick,
    ...props,
  };

  if (typeof to !== 'undefined') {
    return <Link
      to={to}
      {...commonProps}
    >{icon} {children}</Link>;
  }

  if (typeof toModal !== 'undefined') {
    return <ModalLink
      to={toModal}
      {...commonProps}
    >{icon} {children}</ModalLink>;
  }

  return <span
    className={className}
    role="button"
    {...commonProps}
  >{icon} {children}</span>;
};

export default NavButton;
