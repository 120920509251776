import React from 'react';

import useCinematics from '../hooks/useCinematics';

import CinematicList from '../components/CinematicList';
import AppFrame from '../components/AppFrame';
import PageTitle from '../components/PageTitle';

export default () => {
  const { cinematics } = useCinematics();

  return <AppFrame>
    <PageTitle subtitle={`Enjoy ${cinematics.length} hand-picked trailers and cinematics. Frequently updated.`}>
      Browse all cinematics
    </PageTitle>
    <CinematicList cinematics={cinematics} filters={['themes', 'genres', 'games']} />
  </AppFrame>;
};
