import React, { useState, useRef } from 'react';
import { css, keyframes } from '@emotion/react';
import { Link } from 'react-router-dom';

import useCinematics from '../hooks/useCinematics';
import useOnClickOutside from '../hooks/useOnClickOutside';
import useRenderLocation from '../hooks/useRenderLocation';

import NavButton from './NavButton';
import Search from './Search';
import Tooltip from './Tooltip';
import Breadcrumbs from './Breadcrumbs';
import MobileNavigation from './MobileNavigation';
import VideoPoster from './VideoPoster';
import LatestBatch from './LatestBatch';
import NewButton from './NewButton';

import Logo from '../logo.svg';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    padding-top: 0.75rem;
  }

  100% {
    opacity: 1;
    padding-top: 0.2rem;
  }
`;

const style = css`
  margin-bottom: 3rem;
  position: relative;
  z-index: 1;

  header {
    padding: 1.3rem 2rem;
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }

    @media (max-width: 768px) {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  .sub-header {
    border-top: 1px solid #f2f2f2;
    padding: 1.0rem 2rem;

    .extra-tools {
      position: relative;
      margin-left: auto;
      margin-right: -0.8rem;
    }
  }

  .main-header {
    .logo {
      height: 1.6rem;
      width: auto;

      path {
          transition: all 500ms ease-in-out;
          transform-origin: center center;
          transform-box: fill-box
      }

      &:hover {
        .logo_svg__mark-part {
          transform: rotate(180deg);
        }
      }
    }
  }

  .mobile-navigation {
    display: none;
  }

  @media (max-width: 767px) {
    .extra-tools,
    .search {
      display: none;
    }

    .mobile-navigation {
      display: block;
    }

    .primary-nav {
      display: none;
    }

    .submit-container {
      display: none;
    }
  }

  .button:hover .tooltip {
    display: block;
  }
`;

const navStyle = css`
  margin-left: 0.5rem;
  display: flex;

  & > li {
    margin-left: 0.5rem;
    display: flex;
    align-items: center;

    &.expandable {
      position: relative;

      & > .button.open > svg {
        transform: rotate(-180deg);
      }

      .sub-menu {
        z-index: 1;
        max-height: 0;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, calc(100% - 1px));
        white-space: nowrap;
        cursor: default;

        .navigation-content {
          background: #FFF;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
          padding: 1.5rem;
          border-radius: 0.3rem;
        }

        &.open {
          animation: ${fadeIn} 250ms ease-in-out;
          animation-fill-mode: forwards;
          visibility: visible;
          max-height: none;
        }

        .featured-nav-paths {
          display: grid;
          grid-gap: 1.5rem;
          grid-template-columns: repeat(2, 12rem);
          border-bottom: 1px solid #f2f2f2;
          padding-bottom: 1.5rem;
          margin-bottom: 1.5rem;

          a {
            white-space: normal;
            display: block;
            color: inherit;

            &:hover {
              text-decoration: none;

              .description {
                color: #585858;
              }
            }

            .title {
              margin-top: 1.0rem;
              color: inherit;
              font-weight: 500;
            }

            .description {
              color: #999;
              font-size: 0.9rem;
            }
          }
        }

        .sep {
          text-align: center;
          padding-top: 1.5rem;
        }

        ul {
          display: grid;
          grid-gap: 1rem 1.5rem;
          grid-template-columns: repeat(2, 12rem);

          li {
            a {
              display: block;
              color: inherit;
            }

            &.heading {
              color: #999;
            }
          }
        }
      }
    }

    & > .button > svg {
      margin-left: 0.25rem;
      width: 0.7rem;
      height: 0.7rem;
      transition: transform 150ms;
    }
  }
`;

const auxNavStyle = css`
  margin-left: auto;
  display: flex;
  align-items: center;

  li {
    margin-left: 0.5rem;

  }
`;

const Header = () => {
  const location = useRenderLocation();
  const { autoplay, toggleAutoplay } = useCinematics();
  const [isOpen, setOpen] = useState(false);
  const close = () => setOpen(false);
  const open = (event) => {
    if (event.target === event.currentTarget) {
      setOpen(true);
    }
  };
  const ref = useRef(null);
  useOnClickOutside(ref, close);

  return (
    <section css={style}>
      <header className="main-header">
        <Link to="/"><Logo className="logo" /></Link>
        <ul css={navStyle} className="primary-nav">
          <li><NavButton to="/">Home</NavButton></li>
          <li className="expandable">
            <NavButton
              onClick={open}
              onMouseEnter={open}
              onMouseLeave={close}
              className={isOpen ? 'open' : 'closed'}
            >
              Explore <svg fill="currentColor" viewBox="0 0 30 30"><polygon points="15,17.4 4.8,7 2,9.8 15,23 28,9.8 25.2,7 "></polygon></svg>
              <nav ref={ref} className={`sub-menu ${isOpen ? 'open' : 'closed'}`}>
                <div className="navigation-content">
                  <div className="featured-nav-paths">
                    <Link onClick={close} to="/featured/">
                      <VideoPoster imageUrl="https://img.youtube.com/vi/RG2aOpCQQSA/hqdefault.jpg" />
                      <div className="title">Featured cinematics</div>
                      <div className="description">Hand-picked selection. Start here if you're new.</div>
                    </Link>
                    <Link onClick={close} to="/cinematics/">
                      <VideoPoster imageUrl="https://img.youtube.com/vi/wTSXSspClYQ/hqdefault.jpg" />
                      <div className="title">All cinematics</div>
                      <div className="description">Explore all cinematics in a single view.</div>
                    </Link>
                  </div>
                  <ul>
                    <li><Link onClick={close} to="/themes/" stretch>Themes</Link></li>
                    <li><Link onClick={close} to="/games/" stretch>Games</Link></li>
                    <li><Link onClick={close} to="/franchises/" stretch>Franchises</Link></li>
                    <li><Link onClick={close} to="/series/" stretch>Series</Link></li>
                    <li><Link onClick={close} to="/genres/" stretch>Genres</Link></li>
                    <li><Link onClick={close} to="/publishers/" stretch>Publishers</Link></li>
                  </ul>
                  <div className="sep">
                    <NavButton
                      icon="random"
                      onClick={close}
                      to={{
                        pathname: '/random/',
                        state: { location },
                      }}
                      appearance="secondary"
                      stretch
                    >
                      Play random cinematic
                    </NavButton>
                  </div>
                </div>
              </nav>
            </NavButton>
          </li>
          <li><NavButton toModal="/about/">About</NavButton></li>
        </ul>

        <ul css={auxNavStyle} className="aux-nav">
          <li className="search-container"><Search /></li>
          <li className="new-container">
            <NewButton
              itemata-id="tooltip-new"
              data-tip=""
            >
              <Tooltip>
                View latest batch of cinematics
              </Tooltip>
            </NewButton>
          </li>
          <li className="submit-container">
            <NavButton
              itemata-id="tooltip-submit"
              data-tip=""
              toModal="/submit/"
              appearance="primary"
              icon="add-round"
            >
              Submit
              <Tooltip>
                Did we miss a video? Please let us know about it and we'll
                make sure to add it promptly!
              </Tooltip>
            </NavButton>
          </li>
        </ul>
        <MobileNavigation />
      </header>
      <header className="sub-header">
        <Breadcrumbs>
          <LatestBatch />
        </Breadcrumbs>
        <header id="filter-portal" />
        <div className="extra-tools">
          <NavButton
            data-id="tooltip-autoplay"
            data-tip=""
            onClick={toggleAutoplay}
            icon={autoplay ? 'checkbox-checked' : 'checkbox'}
          >
            Auto-play videos
            <Tooltip>
               Whether videos should play automatically when openened or not.
            </Tooltip>
          </NavButton>
        </div>
      </header>
    </section>
  );
};

export default Header;
