import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }) => {
  const [el] = useState(document.createElement('div'));

  useEffect(() => {
    const body = document.querySelector('body');
    body.appendChild(el);

    return () => body.removeChild(el);
  }, []);

  return createPortal(children, el);
};

export default Portal;
