import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import useCinematics from '../hooks/useCinematics';

import PageTitle from '../components/PageTitle';
import CinematicList from '../components/CinematicList';
import AppFrame from '../components/AppFrame';

export default () => {
  const { typeSlug, tagSlug } = useParams();
  const {
    cinematics,
    getTag,
    getType,
    setLastSeen,
  } = useCinematics();

  const type = getType(typeSlug);
  const tag = getTag(`${typeSlug}:${tagSlug}`);

  const matching = cinematics.filter(({ allTags }) => (
    allTags.includes(tag.id)
  ));

  useEffect(() => {
    if (type.slug === 'batches') {
      setLastSeen(new Date());
    }
  }, []);

  return <AppFrame>
    <Helmet>
      <title>{type.name}: {tag.name} - Cinematicon</title>
      <meta property="og:title" content={`${type.name}: ${tag.name}`} />
      {tag.poster && <meta property="og:image" content={tag.poster} />}
    </Helmet>
    <PageTitle pretitle={type.name}>{tag.name}</PageTitle>
    <CinematicList
      cinematics={matching}
      filters={type.filterBy.map((id) => getType(id).slug)}
      filterOpts
    />
  </AppFrame>;
};
