import React, { Suspense } from 'react';
import { css } from '@emotion/react';
// import Select from 'react-select';

const Select = React.lazy(() => import('react-select'));

const styles = {
  container: (base) => ({
    ...base,
    // flex: 1,
  }),
  control: (provided) => ({
    ...provided,
    background: '#f2f2f2',
    color: 'red',
    border: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#888',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#CCC',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#777',
    '&:hover': {
      color: '#CCC',
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: '#999',
    '&:hover': {
      color: '#CCC',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const Fallback = () => (
  <div css={css`line-height: 38px; color: #CCC;`}>Loading ...</div>
);

const Dropdown = (props) => (
  <Suspense fallback={<Fallback />}>
    <Select
      isSearchable
      blurInputOnSelect
      autosize={false}
      styles={styles}
      {...props}
    />
  </Suspense>
);

export default Dropdown;
