import React from 'react';
import { Link } from 'react-router-dom';

import useRenderLocation from '../hooks/useRenderLocation';

const CinematicLink = ({ id, cinematics = [], ...props }) => {
  const location = useRenderLocation();

  return (
    <Link
      to={{
        pathname: `/cinematics/${id}/`,
        state: { location, cinematics, preventScroll: true },
      }}
      {...props}
    />
  );
};

export default CinematicLink;
