export default {
  typography: {
    display: {
      family: 'Rubik, sans-serif',
      thin: 400,
      normal: 400,
      medium: 500,
      bold: 600,
    },
    normal: {
      family: 'Rubik, sans-serif',
      thin: 400,
      normal: 400,
      medium: 500,
      bold: 600,
    },
  },
  palette: {
    primary: '#0046cb',
    secondary: '#0046cb',
    accent: '#0046cb',
  },
  mode: {
    root: {
      background: '#fff',
      color: '#282828',
    },
    primary: {
      color: '#0046cb',
    },
    dark: {
      background: '#111',
      color: '#fff',
    },
  },
  components: {
  },
};
