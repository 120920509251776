import React from 'react';
import { keyframes, css, Global } from '@emotion/react';

const FadeIn = keyframes`
 from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Close = (props) => (
  <div
    className="close"
    css={css`
      text-align: right;
      margin-bottom: 1rem;
      color: #FFF;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }

      svg {
        width: 1.6rem;
        height: 1.6rem;
        pointer-events: none;
      }

      @media (max-width: 768px) {
        margin-bottom: 0.5rem;
      }
    `}
    {...props}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/>
    </svg>
  </div>
);

export default ({
  children,
  onClick: defaultOnClick,
  ...props
}) => {
  const onClick = typeof defaultOnClick !== 'function'
    ? defaultOnClick
    : (event) => {
      if (event.target === event.currentTarget) {
        defaultOnClick(event);
      }
    };

  return (
    <div
      className="overlay"
      onClick={onClick}
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #000c;
        // background: #fff3;
        color: #FFF;
        cursor: pointer;
        z-index: 1;
        overflow: auto;
        animation: ${FadeIn} 200ms linear;
        padding: 1rem 2rem;

        @media (max-width: 768px) {
          padding: 0.5rem 1rem;
        }
      `}
      {...props}
    >
      <Global styles={css`
        body {
          overflow: hidden;

          .application {
            opacity: 0.3;
            filter: blur(15px);
          }
        }
      `} />
      {typeof defaultOnClick !== 'undefined' && <Close onClick={onClick} />}
      {children}
    </div>
  );
};
