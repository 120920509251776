import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { css } from '@emotion/react';

import useRenderLocation from '../hooks/useRenderLocation';

import Cinematic from './Cinematic';
import Spinner from './Spinner';

import Featured from '../views/Featured';
import Cinematics from '../views/Cinematics';
import Home from '../views/Home';
import About from '../views/About';
import Tags from '../views/Tags';
import Tag from '../views/Tag';
import Submit from '../views/Submit';

const AppRouter = ({ cinematics }) => {
  const location = useRenderLocation();

  if (cinematics === null) {
    return <div
      css={css`
        height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #007cc2;
      `}
      >
        <Spinner />
      </div>;
  }

  return (
    <>
      <Switch location={location}>
        <Route exact path="/"><Home /></Route>
        <Route exact path="/featured/"><Featured /></Route>
        <Route exact path="/cinematics/"><Cinematics /></Route>
        <Route exact path="/:typeSlug/"><Tags /></Route>
        <Route exact path="/:typeSlug/:tagSlug/"><Tag /></Route>
      </Switch>
      <Route exact path="/random/">
        <Redirect
          to={{
            pathname: `/cinematics/${cinematics[Math.floor(Math.random() * cinematics.length)].id}/`,
            state: { location },
          }}
        />
      </Route>
      <Route path="/cinematics/:id">
        <Cinematic cinematics={cinematics} />
      </Route>
      <Route path="/about/">
        <About />
      </Route>
      <Route path="/submit/">
        <Submit />
      </Route>
    </>
  );
};

export default AppRouter;
