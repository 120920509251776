import axios from 'axios';

const createClient = ({
  baseURL = process.env.BASE_URL,
  token,
  debug = false,
}) => {
  const client = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  client.interceptors.request.use((request) => {
    if (debug) {
      console.log(request);
    }
    return request;
  });

  const request = async (config) => {
    const { data } = await client(config);
    return data;
  };

  const get = (url, params) => (
    request({
      method: 'get',
      url,
      params,
    })
  );

  // eslint-disable-next-line no-unused-vars
  const post = (url, data) => (
    request({
      method: 'post',
      url,
      data,
    })
  );

  return {
    getCinematics: async () => get('/cinematics/'),
  };
};

export default createClient;
