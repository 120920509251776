import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import parseISO from 'date-fns/parseISO';

import useCinematics from '../hooks/useCinematics';

const style = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .badge {
    display: inline-block;
    background: #6976ec;
    color: #FFF;
    background: #6976ec29;
    color: #6976ec;
    background: #30b77a22;
    color: #30b77a;
    background: #007cc222;
    color: #007cc2;
    padding: 0.15rem 0.4rem;
    border-radius: 0.3rem;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  .text {
    margin: 0 0.6rem;
    font-size: 0.9rem;

    a {
      color: inherit;
      // text-decoration: underline;
      font-weight: 500;
    }
  }

  .date {
    color: #999;
    font-size: 0.8rem;
  }

  @media (max-width: 420px) {
    width: 100%;
    display: none;

    .date {
      display: none;
    }
  }
`;

const LatestBatch = () => {
  const { lastUpdate } = useCinematics();

  if (lastUpdate === null) {
    return null;
  }

  return (
    <div className="latest-batch" css={style}>
      <div className="badge">New!</div>
      <div className="text">Added <Link to={`/batches/${lastUpdate.slug}/`}>{lastUpdate.count} new</Link> cinematic{lastUpdate.count === 1 ? '' : 's'}</div>
      <div className="date">{formatDistanceToNow(parseISO(lastUpdate.date))} ago</div>
    </div>
  );
};

export default LatestBatch;
