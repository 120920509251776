import React, { useRef, useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { Link, useHistory } from 'react-router-dom';

import useCinematics from '../hooks/useCinematics';
import useOnClickOutside from '../hooks/useOnClickOutside';

import Icon from './Icon';

const style = css`
  background: #EEE;
  border-radius: 0.3rem;
  padding: 0.6rem 0.8rem;
  display: flex;
  align-items: center;
  color: #999;
  position: relative;
  z-index: 1;

  input {
    margin-left: 0.4rem;
    color: inherit;
    display: block;
    background: transparent;
    border: 0;
    width: 100%;
    min-width: 4rem;
    max-width: 8rem;

    &:focus {
      outline: none;
    }
  }

  .results {
    position: absolute;
    left: 0;
    width: auto;
    max-width: 17rem;
    min-width: 8rem;
    bottom: 0;
    transform: translateY(100%);
    background: #FFF;
    border-radius: 0.3rem;
    padding: 0.5rem 0.6rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

    .result {
      a {
        white-space: nowrap;
        color: #333;
        font-size: 0.9rem;
        display: block;
        padding: 0.1rem 0.4rem;
        border-radius: 0.2rem;
      }
    }
  }
`;

const Results = ({
  refocus,
  matches,
  onClose,
  onKeyPress,
  selected,
}) => (
  <div
    className="results"
    onKeyUp={onKeyPress}
    onClick={refocus}
  >
    {matches.length === 0
      ? 'No matches'
      : matches.slice(0, 8).map(({ name, slug, url }, i) => (
        <div key={slug} className="result">
          <Link
            style={{ background: selected === i ? '#f2f2f2' : 'transparent' }}
            onClick={onClose}
            to={url}
          >
            {name}
          </Link>
        </div>
      ))
    }
  </div>
);

const Search = () => {
  const { tags, getType } = useCinematics();
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState(null);
  const ref = useRef(null);
  const inputRef = useRef(null);
  const history = useHistory();
  useOnClickOutside(ref, () => setValue(''));

  const matches = useMemo(() => {
    if (!value) {
      return [];
    }

    const type = getType('games');

    return Array.from(tags.values()).filter(({ type: typeId, name }) => (
      typeId === type.id && name.toLowerCase().includes(value.toLowerCase())
    )).map((tag) => ({
      ...tag,
      url: `/${type.slug}/${tag.slug.split(':')[1]}/`,
    }));
  }, [value, tags]);

  const refocus = () => {
    inputRef.current.focus();
  };

  const onKeyPress = (event) => {
    if (event.keyCode === 38) {
      // up
      event.preventDefault();
      if (matches.length === 0) {
        setSelected(null);
      } else if (selected === null) {
        setSelected(0);
      } else if (selected - 1 >= 0) {
        setSelected(selected - 1);
      } else {
        setSelected(null);
      }
    } else if (event.keyCode === 40 || event.keyCode === 9) {
      // down
      event.preventDefault();
      if (matches.length === 0) {
        setSelected(null);
      } else if (selected === null) {
        setSelected(0);
      } else if (selected + 1 < matches.length) {
        setSelected(selected + 1);
      } else {
        setSelected(null);
      }
    } else if (event.keyCode === 13) {
      // enter
      event.preventDefault();
      if (selected !== null) {
        setValue('');
        inputRef.current.blur();
        history.push(matches[selected].url);
      }
    } else if (event.keyCode === 27) {
      // escape
      setValue('');
      inputRef.current.blur();
    } else {
      setSelected(null);
    }
  };

  return (
    <div
      className="search"
      css={style}
      ref={ref}
      onKeyDown={onKeyPress}
      onClick={refocus}
    >
      <Icon icon="search" />
      <input
        ref={inputRef}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setSelected(null);
        }}
        type="text"
        placeholder="Search ..."
      />
      {value.length > 0 && (
        <Results
          matches={matches}
          onClose={(event) => {
            event.stopPropagation();
            setValue('');
          }}
          onKeyPress={onKeyPress}
          refocus={refocus}
          selected={selected}
        />
      )}
    </div>
  );
};

export default Search;
