import React, { useMemo } from 'react';

import useCinematics from '../hooks/useCinematics';

import CinematicList from '../components/CinematicList';
import AppFrame from '../components/AppFrame';
import PageTitle from '../components/PageTitle';

export default () => {
  const { cinematics: allCinematics } = useCinematics();
  const cinematics = useMemo(() => (
    allCinematics.filter(({ featured }) => featured)
  ), [allCinematics]);

  return <AppFrame>
    <PageTitle>
      Featured cinematics
    </PageTitle>
    <CinematicList
      cinematics={cinematics}
      filters={['themes', 'genres', 'games']}
    />
  </AppFrame>;
};
