import React from 'react';
import { css } from '@emotion/react';

const style = ({ imageUrl, format, rounded }) => css`
  position: relative;
  ${rounded && 'border-radius: 0.3rem;'}
  overflow: hidden;
  padding-top: 56.25%;
  width: 100%;
  ${!imageUrl && 'background: #282828;'}

  div {
    width: ${format === 'blurred' ? '151%' : '101%'};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    height: auto;
    width: 100%;
    display: block;
    ${format === 'blurred' && 'filter: blur(10px);'}
  }
`;

export default ({
  imageUrl,
  format,
  rounded = true,
  ...props
}) => (
  <div css={style({ imageUrl, format, rounded })} className="video-poster" {...props}>
    {Boolean(imageUrl) && <div><img src={imageUrl} loading="lazy" /></div>}
  </div>
);
