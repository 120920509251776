import React from 'react';
import { css } from '@emotion/react';

import useCinematics from '../hooks/useCinematics';

import TagType from './TagType';

const style = css`
  margin-bottom: 1.5rem;
`;

const CinematicTags = ({ cinematic }) => {
  const { getTag, getType } = useCinematics();

  const tagGroups = cinematic.allTags.reduce((acc, id) => {
    const tag = getTag(id);
    if (!acc.has(tag.type)) {
      acc.set(tag.type, []);
    }

    acc.get(tag.type).push(tag);

    return acc;
  }, new Map());

  const segments = [
    [
      'games',
      'expansions',
      'dlcs',
      'franchises',
      'series',
    ],
    [
      'themes',
      'genres',
    ],
    [
      'styles',
      'types',
    ],
    [
      'publishers',
      'developers',
    ],
  ];

  const asBadges = ['themes', 'genres'];

  return segments.map((tagSlugs, i) => (
    <div css={style} key={i}>
      {tagSlugs.map((slug) => {
        const type = getType(slug);
        return Array.from(tagGroups.entries())
          .filter(([id]) => id === type.id)
          .map(([id, tags]) => (
            <TagType
              key={id}
              typeId={id}
              tags={tags}
              badges={asBadges.includes(type.slug)}
            />
          ));
      })}
    </div>
  ));
};

export default CinematicTags;
