import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { css } from '@emotion/react';

import useCinematics from '../hooks/useCinematics';
import { narrowGridStyle, wideGridStyle } from '../utils/styles';

import PageTitle from '../components/PageTitle';
import AppFrame from '../components/AppFrame';
import GamePoster from '../components/GamePoster';
import Collection from '../components/Collection';

const style = css`
  text-decoration: none;
  color: inherit;
  position: relative;

  .name {
    margin-top: 0.5rem;
  }
`;

const titleStyle = css`
  h1 span {
    text-transform: lowercase;
  }
`;

export default () => {
  const { typeSlug } = useParams();
  const { tags, getType } = useCinematics();

  const type = getType(typeSlug);
  const matching = Array.from(tags.values())
    .filter(({ type: _type }) => _type === type.id);

  return <AppFrame>
    <PageTitle css={titleStyle}>Explore by <span>{type.name}</span></PageTitle>
    <div css={type.posterFormat === 'portrait' ? narrowGridStyle : wideGridStyle}>
      {matching.map((tag) => (
        <Link
          key={tag.slug}
          className={type.posterFormat}
          css={style}
          to={`/${type.slug}/${tag.slug.split(':')[1]}/`}
        >
          {type.posterFormat === 'portrait'
            ? <GamePoster imageUrl={tag.poster} />
            : <Collection tag={tag} />
          }
          <div className="name">{tag.name}</div>
        </Link>
      ))}
    </div>
  </AppFrame>;
};
