import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';

import useCinematics from '../hooks/useCinematics';
import Property from './Property';

const style = css`
  .badge {
    display: inline-block;
    background: #282828;
    background: #007cc222;
    padding: 0.3rem 0.6rem;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    margin: 0.2rem;
  }
`;

export default ({
  typeId,
  tags,
  badges = false,
  ...props
}) => {
  const { getType } = useCinematics();
  const type = getType(typeId);

  return (
    <Property
      css={style}
      label={tags.length === 1 ? type.name : type.pluralName}
      values={tags.map(({ name, slug }, i) => (
        <>
          {i > 0 && !badges ? ', ' : ''}
          <Link
            className={badges ? 'badge' : 'normal'}
            key={slug} to={`/${type.slug}/${slug.split(':')[1]}/`}
          >{name}</Link>
        </>
      ))}
      {...props}
    />
  );
};
