import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Link, useRouteMatch } from 'react-router-dom';
import { css } from '@emotion/react';

import useCinematics from '../hooks/useCinematics';

const style = css`
  display: flex;
  align-items: center;

  > * {
    color: #999;;
  }

  > *:last-child {
    color: #007cc2;
  }

  > *:not(:last-child) {
    &:after {
      content: ">";
      display: inline-block;
      padding: 0 0.5rem;
      color: #999;;
    }
  }
`;

const TagCrumb = ({ match, props: { type } }) => {
  const { getTag } = useCinematics();
  const tag = getTag(`${type}:${match.params.slug}`);
  return <span>{tag ? tag.name : '...'}</span>;
};

const routes = [
  { path: '/games/:slug/', breadcrumb: TagCrumb, props: { type: 'games' } },
  { path: '/genres/:slug/', breadcrumb: TagCrumb, props: { type: 'genres' } },
  { path: '/themes/:slug/', breadcrumb: TagCrumb, props: { type: 'themes' } },
  { path: '/franchises/:slug/', breadcrumb: TagCrumb, props: { type: 'franchises' } },
  { path: '/series/:slug/', breadcrumb: TagCrumb, props: { type: 'series' } },
  { path: '/developers/:slug/', breadcrumb: TagCrumb, props: { type: 'developers' } },
  { path: '/publishers/:slug/', breadcrumb: TagCrumb, props: { type: 'publishers' } },
  { path: '/styles/:slug/', breadcrumb: TagCrumb, props: { type: 'styles' } },
];

export default ({ children }) => {
  const { cinematics } = useCinematics();
  const breadcrumbs = useBreadcrumbs(routes);
  const isCinematic = useRouteMatch('/cinematics/:id');

  if (cinematics === null || isCinematic) {
    return null;
  }

  if (breadcrumbs.length <= 1) {
    return children;
  }

  return (
    <div className="breadcrumbs" css={style}>
      {breadcrumbs.map(({ breadcrumb, match }) => (
        <Link key={match.url} to={match.url}>{breadcrumb}</Link>
      ))}
    </div>
  );
};
