import React from 'react';
import { css } from '@emotion/react';

const style = css`
  padding: 0 6rem;
  max-width: 2200px;
  margin: 0 auto;

  @media (max-width: 1400px) {
    padding: 0 4rem;
  }

  @media (max-width: 1200px) {
    padding: 0 2rem;
  }

  @media (max-width: 1024px) {
    padding: 0 1.5rem;
  }

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const footerStyle = css`
  color: #CCC;
  text-align: center;
  padding: 2rem;
`;

export default ({ children, ...props }) => (
  <div css={style} {...props}>
    {children}
    <div css={footerStyle}>
      &copy; Copyright 2021
    </div>
  </div>
);
