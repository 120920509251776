import React from 'react';
import { css } from '@emotion/react';

import SectionTitle from '../components/SectionTitle';
import Modal from '../components/Modal';

const style = css`
  background: #FFF;
  max-width: 20rem;
  box-sizing: border-box;
  padding: 2rem;
  max-width: 40rem;
  color: #282828;
  background: #fff;
  border-radius: 0.2rem;
  margin: 0 auto;
  cursor: auto;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  p {
    margin-bottom: 1.5rem;
  }

  .submit-box {
    padding: 1rem;
    background: #F8f8f8;
    border-radius: 0.3rem;

    p {
      margin: 0;
    }
  }
`;

export default () => (
  <Modal css={style}>
    <SectionTitle>Submit cinematic</SectionTitle>
    <p>We can't catch them all 🤭! If you know of a cinematic that we haven't
    added yet, please send it our way and we'll get it posted asap!</p>
    <p>Would be awesome if you could include the name of the game and a link
    to a YouTube page (preferably on the content owner's channel) 🥺.</p>
    <div className="submit-box">
      <p>We'll create fancy system for submitting stuff later. Promise! But
        we're focusing on adding cinematics at the moment. Please submit your
        suggestions to <a
    href="mailto:cinematicontv@gmail.com">cinematicontv@gmail.com</a> in the
    meantime 🙏</p>
    </div>
  </Modal>
);
