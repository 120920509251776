import React, { useState } from 'react';
import { css } from '@emotion/react';
import Overlay from './Overlay';
import Portal from './Portal';
import NavButton from './NavButton';
import Icon from './Icon';

const style = css`
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  max-width: 18rem;
  background: #FFF;
  box-sizing: border-box;
  cursor: default;
  padding: 1rem;
  color: #151515;
  overflow: auto;
`;

const togglerStyle = css`
  padding: 0.2rem;

  .icon {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

const navStyle = css`
  overflow: auto;

  li {
    padding: 0.5rem 0;

    &.sep {
      border-top: 1px solid #f2f2f2;
    }

    .button {
      font-size: 1.1rem;
    }
  }
`;

export default () => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  const close = () => setOpen(false);

  return (
    <div className="mobile-navigation">
      <NavButton onClick={toggleOpen} css={togglerStyle}><Icon icon="menu" /></NavButton>
      {open && (
        <Portal>
          <Overlay onClick={toggleOpen}>
            <div className="mobile-menu" css={style}>
              <ul css={navStyle} className="primary-nav">
                <li><NavButton onClick={close} to="/" stretch>Home</NavButton></li>
                <li className="sep"><NavButton onClick={close} to="/cinematics/" stretch>All cinematics</NavButton></li>
                <li><NavButton onClick={close} to="/featured/" stretch>Featured cinematics</NavButton></li>
                <li className="sep"><NavButton onClick={close} to="/themes/" stretch>By theme</NavButton></li>
                <li><NavButton onClick={close} to="/games/" stretch>By game</NavButton></li>
                <li><NavButton onClick={close} to="/franchises/" stretch>By franchise</NavButton></li>
                <li><NavButton onClick={close} to="/series/" stretch>By series</NavButton></li>
                <li><NavButton onClick={close} to="/genres/" stretch>By genre</NavButton></li>
                <li><NavButton onClick={close} to="/publishers/" stretch>By publishers</NavButton></li>
                <li className="sep"><NavButton onClick={close} toModal="/about/" stretch>About</NavButton></li>
                <li>
                  <NavButton
                    data-id="tooltip-submit"
                    data-tip=""
                    toModal="/submit/"
                    appearance="primary"
                    icon="add-round"
                    onClick={close}
                    stretch
                  >
                    Submit cinematic
                  </NavButton>
                </li>
              </ul>
            </div>
          </Overlay>
        </Portal>
      )}
    </div>
  );
};
