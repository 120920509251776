import React from 'react';
import { css } from '@emotion/react';
import { Link, useParams } from 'react-router-dom';
import format from 'date-fns/format';
import { Helmet } from 'react-helmet';

import theme from '../theme';
import useRelatedCinematics from '../hooks/useRelatedCinematics';
import useCinematics from '../hooks/useCinematics';

import CinematicLink from './CinematicLink';
import Embed from './Embed';
import Modal from './Modal';
import Icon from './Icon';
import CinematicTags from './CinematicTags';
import Property from './Property';

const Cinematic = ({ cinematics }) => {
  const { id: rawId } = useParams();
  const id = parseInt(rawId, 10);

  const related = useRelatedCinematics();
  const { getGame } = useCinematics();

  const index = related.findIndex(({ id: _id }) => _id === id);

  const Next = index === -1 || index === related.length - 1
    ? <span><Icon icon="next" /></span>
    : <CinematicLink id={related[index + 1].id} cinematics={related}>
        <Icon icon="next" />
      </CinematicLink>;
  const Prev = index === -1 || index === 0
    ? <span><Icon icon="prev" /></span>
    : <CinematicLink id={related[index - 1].id} cinematics={related}>
        <Icon icon="prev" />
      </CinematicLink>;

  const cinematic = cinematics.find(({ id: _id }) => _id === id);
  const {
    youtubeId,
    name,
    poster,
    releasedAt,
    publishedAt,
  } = cinematic;

  const game = getGame(cinematic);

  return (
    <Modal
      css={css`
        box-sizing: border-box;
        padding: 2rem;
        width: 100%;
        max-width: calc(100vh + 15rem);
        color: ${theme.mode.dark.color};
        background: ${theme.mode.dark.background};
        border-radius: 0.2rem;
        margin: 0 auto;
        cursor: auto;
        box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
        margin-bottom: 2rem;

        .meta-data {
          margin: 2rem auto 0;
          // max-width: 80rem;
        }

        @media (max-width: 768px) {
          padding: 1rem;
        }
      `}
    >
      <Helmet>
        <title>{name} - Cinematicon</title>
        <meta property="og:title" content={`${name} - Cinematicon`} />
        <meta property="og:type" content="video.movie" />
        <meta property="og:image" content={poster} />
      </Helmet>
      <header
        css={css`
          margin: 0 auto 2rem;
          // max-width: 80rem;
          display: flex;
          align-items: center;
        `}
      >
        <div>
          <div>{game ? <Link to={`/games/${game.slug.split(':')[1]}/`}>{game.name}</Link> : '0'}</div>
          <div css={css`font-size: 1.2rem;`}>{name}</div>
        </div>
        <div
          css={css`
            display: flex;
            margin-left: auto;
            align-items: center;

            span,
            a {
              color: #FFF;
              font-size: 2rem;
              padding: 0.5rem;
              display: inline-block;
              line-height: normal;
              background: #282828;
              border-radius: 0.25rem;

              &:last-child {
                margin-left: 0.5rem;
              }
            }

            span {
              opacity: 0.5;
            }

            a {
              text-decoration: none;
            }
          `}
        >
          {Prev} {Next}
        </div>
      </header>
      <Embed youtubeId={youtubeId} />

      <div className="meta-data">
        <CinematicTags cinematic={cinematic} />
        <Property
          label="Added at"
          values={[format(publishedAt, 'do MMMM yyyy')]}
        />
        <Property
          label="Released at"
          values={[format(releasedAt, 'do MMMM yyyy')]}
        />
      </div>
      <div>
      </div>
    </Modal>
  );
};

export default Cinematic;
