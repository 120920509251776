import React from 'react';
import { css } from '@emotion/react';

const style = ({ imageUrl }) => css`
  position: relative;
  border-radius: 0.3rem;
  overflow: hidden;
  padding-top: 142.42424242424243%;
  width: 100%;
  ${!imageUrl && 'background: #282828;'}

  img {
    display: block;
    width: auto;
    height: 100%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default ({ imageUrl, ...props }) => (
  <div css={style({ imageUrl })} className="game-poster" {...props}>
      {Boolean(imageUrl) && <img src={imageUrl} loading="lazy" />}
  </div>
);
