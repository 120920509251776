import React from 'react';
import { css } from '@emotion/react';

import useCinematics from '../hooks/useCinematics';

import VideoPoster from './VideoPoster';

const style = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 0.3rem;
  overflow: hidden;
  // padding-top: 56.25%;
`;

const SAMPLES = 4;

export default ({ tag }) => {
  const { cinematics } = useCinematics();

  const matches = [];

  for (let i = 0; i < cinematics.length; i += 1) {
    const cinematic = cinematics[i];
    if (cinematic.allTags.includes(tag.id)) {
      matches.push(cinematic);
    }

    if (matches.length === SAMPLES) {
      break;
    }
  }

  if (matches.length === 1) {
    return <VideoPoster imageUrl={matches[0].poster}/>;
  }

  return (
    <div css={style}>
      {Array(SAMPLES).fill().map((_, i) => (
        <VideoPoster
          imageUrl={matches[i] && matches[i].poster}
          rounded={false}
        />
      ))}
    </div>
  );
};
