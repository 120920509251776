import React from 'react';
import { css } from '@emotion/react';

import { wideGridStyle } from '../utils/styles';
import Dropdown from './Dropdown';

const style = css`
  ${wideGridStyle}
  margin-bottom: 2rem;
  grid-gap: 1rem 2rem;
`;

export default ({
  filters = [],
  filterConfigs,
  values,
  orderConfig: { setOrder, order, orderOpts },
}) => {
  if (filters.length === 0) {
    return null;
  }

  return (
    <div css={style}>
      <div className="filter">
        <Dropdown
          value={order}
          onChange={setOrder}
          options={orderOpts}
        />
      </div>
      {filters.map((key) => {
        const {
          setter,
          opts,
          placeholder,
          hidden,
        } = filterConfigs[key];

        if (hidden) {
          return null;
        }

        return (
          <div className="filter" key={key}>
            <Dropdown
              value={values[key]}
              onChange={(value) => setter(value.value === null ? null : value)}
              options={opts}
              placeholder={placeholder}
            />
          </div>
        );
      })}
    </div>
  );
};
