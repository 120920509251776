import React from 'react';
import { css } from '@emotion/react';

export default ({ children, aux }) => (
  <div
    css={css`
      margin-bottom: 2rem;
      display: flex;
      align-items: flex-end;

      .title {
        font-size: 1.6rem;
        font-weight: 900;
        line-height: 130%;
      }

      .aux {
        margin-left: auto;
      }
    `}
  >
    <div className="title">{children}</div>
    {typeof aux !== 'undefined' ? <div className="aux">{aux}</div> : null}
  </div>
);
