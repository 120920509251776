import React, { useMemo } from 'react';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import { css } from '@emotion/react';

import Icon from './Icon';
import NavButton from './NavButton';

import useCinematics from '../hooks/useCinematics';

const style = ({ showIndicator }) => css`
  position: relative;

  .indicator {
    width: 0.5rem;
    height: 0.5rem;
    background: #ff725a;
    border: 2px solid #FFF;
    border-radius: 50%;
    position: absolute;
    top: 0.35rem;
    right: 0.35rem;
    pointer-events: none;
  }


  .button {
    color: ${showIndicator ? '#999' : '#bbb'};
    padding: 0 0.5rem;
  }
`;

const NewButton = ({ children, ...props }) => {
  const { lastSeen, lastUpdate } = useCinematics();

  const showIndicator = useMemo(() => {
    if (lastSeen === null) {
      return true;
    }

    if (lastUpdate !== null && isAfter(parseISO(lastUpdate.date), parseISO(lastSeen))) {
      return true;
    }

    return false;
  }, [lastSeen, lastUpdate]);

  return (
    <div css={style({ showIndicator })} {...props}>
      <NavButton to={lastUpdate === null ? /batches/ : `/batches/${lastUpdate.slug}/`}>
        <Icon icon="new" size="1.4rem" />
        {children}
      </NavButton>
      {showIndicator && <div className="indicator" />}
    </div>
  );
};

export default NewButton;
