import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';

import useCinematics from '../hooks/useCinematics';

import FeatureList from '../components/FeatureList';
import CinematicList from '../components/CinematicList';
import AppFrame from '../components/AppFrame';
import PageTitle from '../components/PageTitle';
import SectionTitle from '../components/SectionTitle';
import NavButton from '../components/NavButton';
import Icon from '../components/Icon';
import CinematicLink from '../components/CinematicLink';

import heroBg from '../images/backgrounds/hero/ardenweald.jpg';
import wowImg from '../images/games/wow-shadowlands.png';
import fantasyBg from '../images/backgrounds/medium/fantasy.jpg';
import scifiBg from '../images/backgrounds/medium/sc-ii.jpg';
import shadowlandsBg from '../images/backgrounds/small/shadowlands-cinematic.jpg';

const heroStyle = css`
  z-index: 0;
  position: relative;
  border-radius: 0.3rem;
  color: #FFF;
  background: #282828;
  padding: 6rem 5rem;
  margin-bottom: 4rem;
  overflow: hidden;

  &:before {
    z-index: 1;
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${heroBg});
  }

  .spot a {
    display: block;
    z-index: 2;
    position: absolute;
    right: 5rem;
    bottom: 6rem;
    border-radius: 0.3rem;
    text-align: center;
    max-width: 15rem;
    padding: 1rem;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 30px #f2e48228;
    background: #282828;
    color: #ebbd67;
    color: #f2e482;

    &:hover {
      box-shadow: 0 0 30px #f2e48233;
    }

    &:before {
      z-index: 1;
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.5;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(${shadowlandsBg});
    }

    .play {
      position: relative;
      z-index: 2;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        margin-right: 0.4rem;
      }
    }

    img {
      position: relative;
      z-index: 2;
      width: 100%;
      height: auto;
    }
  }

  .content { 
    position: relative;
    z-index: 2;
    max-width: 30rem;

    .badge {
      display: inline-block;
      background: #ffc050;
      color: #282828;
      padding: 0.2rem 0.4rem;
      border-radius: 0.2rem;
      margin-bottom: 1rem;
      font-size: 0.8rem;
      font-weight: 500;
      text-transform: uppercase;
    }

    .title {
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 1.3;
      margin-bottom: 2rem;
      font-family: 'Titillium Web', Rubik, sans-serif;
      font-weight: 700;

      strong {
        // color: #bbd775;
      }
    }

    .body {
      font-size: 1.2rem;

      p {
        margin-bottom: 2rem;
        opacity: 0.8;
      }

      .button {
        font-size: 1.1rem;
        padding: 0 1.0rem;
        height: 2.9rem;
      }
    }
  }

  @media (max-width: 1024px) {
    .spot a {
      margin-top: 3rem;
      right: 0;
      bottom: 0;
      position: relative;
    }
  }

  @media (max-width: 768px) {
    padding: 3rem 2rem 2rem;

    .content {
      .title {
        font-size: 1.8rem;
      }
    }
  }


  @media (max-width: 420px) {
    margin-top: -5rem;

    .spot a {
      max-width: none;
    }
  }
`;

export default () => {
  const { cinematics, getTag } = useCinematics();

  const featuredList = useMemo(() => (
    cinematics.filter(({ featured }) => featured).slice(0, 14)
  ), [cinematics]);

  const fantasyList = useMemo(() => (
    cinematics.filter(({ allTags }) => {
      const tag = getTag('themes:fantasy');
      return allTags.includes(tag.id);
    }).slice(0, 14)
  ), [cinematics]);

  const scifiList = useMemo(() => (
    cinematics.filter(({ allTags }) => {
      const tag = getTag('themes:sci-fi');
      return allTags.includes(tag.id);
    }).slice(0, 14)
  ), [cinematics]);

  const recentList = useMemo(() => (
    cinematics.sort((a, b) => b.publishedAt - a.publishedAt).slice(0, 14)
  ), [cinematics]);

  return <AppFrame>
    <div className="hero" css={heroStyle}>
      <div className="content">
        <div className="badge">Hello :)</div>
        <h1 className="title">Stay a while and watch some <strong>video game cinematics</strong></h1>
        <div className="body">
          <p>Trailers and cutscenes are a big and enjoyable part of gaming.
            That's why we set out to create a place where they can exist with
            some dignity.</p>
          <p>All videos are catalogized and the content is frequently updated.
            We hope you'll like it!</p>
          <div><NavButton to="/cinematics/" appearance="primary">Explore cinematics <Icon icon="next" /></NavButton></div>
        </div>
      </div>
      <div className="spot">
        <CinematicLink id={2}>
          <img src={wowImg} />
          <div className="play"><Icon icon="play" /> Play trailer</div>
        </CinematicLink>
      </div>
    </div>

    <SectionTitle aux={<Link to="/featured/">View all</Link>}>
      Featured cinematics
    </SectionTitle>
    <CinematicList cinematics={featuredList} cut />

    <SectionTitle aux={<Link to="/cinematics/">View all</Link>}>
      Most recently added
    </SectionTitle>
    <CinematicList cinematics={recentList} cut />

    <PageTitle subtitle={<div>Make sure you don't miss the rest of the <Link to="/themes/">themes</Link>.</div>}>
      Explore by theme
    </PageTitle>

    <FeatureList
      cinematics={fantasyList}
      title="Fantasy"
      to="/themes/fantasy/"
      css={css`
        .header:before {
          background-image: url(${fantasyBg});
        }
      `}
    >
      <p>From Tolkien to World of Warcraft. One of the &mdash; if
        not <em>the</em> &mdash; most entertaining themes when it comes to
      cinematics.</p>
    </FeatureList>

    <FeatureList
      cinematics={scifiList}
      title="Science fiction"
      to="/themes/sci-fi/"
      css={css`
        .header:before {
          background-image: url(${scifiBg});
        }
      `}
    >
      <p>Get a glimpse of one of the many possible versions of the future.
        Often gloomy, often grim, often dark. Whatever it is, it is a great
      recipe for some awesome videos.</p>
    </FeatureList>
  </AppFrame>;
};
