import { useState, useMemo } from 'react';

import useCinematics from './useCinematics';

const orderOpts = [
  { label: 'Released, newest first', value: { field: 'releasedAt', direction: 'asc' } },
  { label: 'Released, oldest first', value: { field: 'releasedAt', direction: 'desc' } },
  { label: 'Added, newest first', value: { field: 'publishedAt', direction: 'asc' } },
  { label: 'Added, oldest first', value: { field: 'publishedAt', direction: 'desc' } },
];

export default ({
  cinematics = [],
  filters = [],
  filterOpts = false,
}) => {
  const {
    tags,
    getType,
  } = useCinematics();

  const [order, setOrder] = useState(orderOpts[0]);
  const [values, setValues] = useState(filters.reduce((acc, filter) => ({
    [filter]: null,
    ...acc,
  }), {}));
  const tagTypes = useMemo(() => filters.map((slug) => getType(slug)), [filters]);

  const opts = useMemo(() => tagTypes.reduce((acc, type) => ({
    [type.slug]: Array.from(tags.values())
      .filter(({ type: _type }) => _type === type.id)
      .filter(({ id }) => (
        !filterOpts || cinematics.some(({ allTags }) => allTags.includes(id))
      ))
      .map(({ id, name }) => ({ value: id, label: name })),
    ...acc,
  }), {}), [tags, filters, filterOpts]);

  const filterConfigs = useMemo(() => tagTypes.reduce((acc, type) => ({
    [type.slug]: {
      value: values[type.slug],
      setter: (value) => setValues({
        ...values,
        [type.slug]: value,
      }),
      hidden: opts[type.slug].length < 2,
      opts: [{ value: null, label: `All ${type.pluralName}` }, ...opts[type.slug]],
      placeholder: `Filter by ${type.name}`,
    },
    ...acc,
  }), {}), [filters, values, filterOpts]);

  const _cinematics = useMemo(() => (
    filters
      .reduce((acc, key) => {
        const value = values[key];

        // TODO: `value` can be undefined for some reason. Let's find out why
        // some day.
        if (value === null || typeof value === 'undefined') {
          return acc;
        }

        return acc.filter((cinematic) => (
          cinematic.allTags.includes(value.value)
        ));
      }, cinematics)
      .sort((a, b) => (
        order.value.direction === 'asc'
          ? b[order.value.field] - a[order.value.field]
          : a[order.value.field] - b[order.value.field]
      ))
  ), [values, order, cinematics]);

  return {
    cinematics: _cinematics,
    values,
    filterConfigs,
    order,
    setOrder,
    orderOpts,
    orderConfig: { order, setOrder, orderOpts },
  };
};
