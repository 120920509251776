import React from 'react';
import { css } from '@emotion/react';

const style = css`
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;

  .label {
    padding-right: 1rem;
    width: 7rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .values {
    color: #bbb;
  }
`;

const Property = ({ label, values, ...props }) => (
  <div css={style} className="property" {...props}>
    <div className="label">{label}</div>
    <div className="values">
      {values.map((value, i) => <span key={i}>{value}</span>)}
    </div>
  </div>
);

export default Property;
