import React, { useEffect } from 'react';
import { css, Global } from '@emotion/react';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import useTheme from '../hooks/useTheme';
import useCinematics from '../hooks/useCinematics';
import useLoadCinematics from '../hooks/useLoadCinematics';
import reset from '../reset';

import Header from './Header';
import AppRouter from './AppRouter';

const ScrollToTop = withRouter(({ history }) => {
  useEffect(() => {
    const unlisten = history.listen((location) => {
      const preventScroll = Boolean(location.state && location.state.preventScroll);

      if (!preventScroll) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
});

const App = () => {
  const { cinematics } = useCinematics();
  const { theme, color, background } = useTheme();
  useLoadCinematics();

  return (
    <Router>
      <Helmet>
        <title>Video game cinematic trailers and cutscenes - Cinematicon</title>
      </Helmet>
      <ScrollToTop />
      <Global styles={css`
        ${reset}

        html {
          font-size: 16px;
          font-family: ${theme.typography.normal.family};
          height: 100%;
        }

        html,
        body {
          height: 100%;
        }

        body {
          scroll-behavior: smooth;
          line-height: 1.6;
          color: ${color('root')};
          background: ${background('root')};
        }

        em {
          font-style: italic;
        }

        input,
        button,
        textarea {
          font-family: ${theme.typography.normal.family};
        }

        a {
          color: #007cc2;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      `} />
      <div className="application">
        <Header />
        <AppRouter cinematics={cinematics} />
      </div>
    </Router>
  );
};

export default App;
