import React from 'react';
import { css } from '@emotion/react';

export default ({
  subtitle,
  pretitle,
  children,
  ...props
}) => (
  <div
    className="page-title"
    css={css`
      text-align: center;
      margin-bottom: 5rem;
    `}
    {...props}
  >
    {typeof pretitle !== 'undefined' && (
      <div
        css={css`
          line-height: 130%;
          color: #999;
        `}
      >
        {pretitle}
      </div>
    )}
    <h1
      css={css`
        display: inline-block;
        font-size: 2.2rem;
        font-weight: 900;
        line-height: 130%;
        margin-bottom: 0.15rem;

        @media (max-width: 768px) {
          font-size: 1.8rem;
        }
      `}
    >{children}</h1>
    {typeof subtitle !== 'undefined' && (
      <div
        css={css`
          font-size: 1.2rem;
          line-height: 130%;
          color: #999;
        `}
      >
        {subtitle}
      </div>
    )}
  </div>
);
