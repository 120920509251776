import { useLocation } from 'react-router-dom';

const useRelatedCinematics = () => {
  const currentLocation = useLocation();

  return currentLocation.state && currentLocation.state.cinematics
    ? currentLocation.state.cinematics
    : [];
};

export default useRelatedCinematics;
