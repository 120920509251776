import React from 'react';

import { css } from '@emotion/react';

import useCinematics from '../hooks/useCinematics';

const Embed = ({ aspectRatio = '16:9', youtubeId }) => {
  const { autoplay } = useCinematics();

  return (
    <div
      css={css`
        overflow: hidden;
        position: relative;
        width: 100%;

        &::after {
          display: block;
          content: "";
          padding-top: 56.25%;
        }
      `}
    >
      <iframe
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        `}
        data-aspect-ratio={aspectRatio}
        src={`https://www.youtube.com/embed/${youtubeId}?autoplay=${autoplay ? 1 : 0}&&modestbranding=1`}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Embed;
