import React from 'react';
import { css } from '@emotion/react';

import theme from '../theme';

import SectionTitle from '../components/SectionTitle';
import Modal from '../components/Modal';

const style = css`
  color: ${theme.mode.dark.color};
  background: ${theme.mode.dark.background};
  box-sizing: border-box;
  padding: 2rem;
  max-width: 50rem;
  border-radius: 0.2rem;
  margin: 0 auto;
  cursor: auto;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  p {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
  }
`;

export default () => (
  <Modal css={style}>
    <SectionTitle>Hello there 🖖</SectionTitle>
    <p>We've always loved trailers. But, while there were lots of
      options for movie trailers out there, there weren't a lot of decent
      options for devouring video game trailers (and cutscenes!).</p>
    <p>We found that both weird and disappointing. So we created Cinematicon.
      A tool to help us procrastinate better, basically.</p>
    <p>¯\_(ツ)_/¯</p>
    <p>Anyway, we've added some web analytics and stuff to the site and it
      looks like we're not alone. But those are numbers and numbers only give
      us like half the picture. It would be super-awesome to hear from
      you 💓. Odds are your ideas about how you want to consume
      cinematics are a lot better than ours 🤯.</p>

    <p>Some places where you can get ahold of us:</p>

    <ul>
      <li><a target="_blank" href="mailto:cinematicontv@gmail.com">cinematicontv@gmail.com</a></li>
      <li><a target="_blank" href="https://twitter.com/cinematicontv">@cinematicontv (Twitter)</a></li>
    </ul>
  </Modal>
);
